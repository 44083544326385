<template>
  <div
    class="main"
    :class="{ 'hasBottom': showChangePay }"
  >
    <div class="tab flex align-items-c ft14">
      <div
        class="item"
        :class="tabIndex == '0' ? 'curr' : ''"
        :style="{
          color: (tabIndex == '0' ? color_data.specialColor : '')
        }"
        @click="changeTabAction('0')"
      >全部
        <div
          class="bg"
          :style="{ background: color_data.specialColor }"
        />
      </div>
      <div
        class="item"
        :class="tabIndex == '1' ? 'curr' : ''"
        :style="{
          color: (tabIndex == '1' ? color_data.specialColor : '')
        }"
        @click="changeTabAction('1')"
      >待付款
        <div
          class="bg"
          :style="{ background: color_data.specialColor }"
        />
      </div>
      <div
        class="item"
        :class="tabIndex == '2' ? 'curr' : ''"
        :style="{
          color: (tabIndex == '2' ? color_data.specialColor : '')
        }"
        @click="changeTabAction('2')"
      >待发货
        <div
          class="bg"
          :style="{ background: color_data.specialColor }"
        />
      </div>
      <div
        class="item"
        :class="tabIndex == '3' ? 'curr' : ''"
        :style="{
          color: (tabIndex == '3' ? color_data.specialColor : '')
        }"
        @click="changeTabAction('3')"
      >待收货
        <div
          class="bg"
          :style="{ background: color_data.specialColor }"
        />
      </div>
      <div
        class="item"
        :class="tabIndex == '5' ? 'curr' : ''"
        :style="{
          color: (tabIndex == '5' ? color_data.specialColor : '')
        }"
        @click="changeTabAction('5')"
      >已完成
        <div
          class="bg"
          :style="{ background: color_data.specialColor }"
        />
      </div>
      <div
        class="item"
        :class="tabIndex == '6' ? 'curr' : ''"
        :style="{
          color: (tabIndex == '6' ? color_data.specialColor : '')
        }"
        @click="changeTabAction('6')"
      >售后
        <div
          class="bg"
          :style="{ background: color_data.specialColor }"
        />
      </div>
    </div>

    <van-list
      v-model:loading="loading"
      :immediate-check="false"
      :finished="finished"
      :finished-text="total > 0 ? '没有更多了' : '暂无数据'"
      @load="loadNextPage"
    >

      <div
        v-if="tabIndex < 6"
        class="list ft12"
      >
        <div
          class="order"
          v-for="(item, index) in list"
          :key="index"
          @click="changeOrderAction(index)"
        >
          <div class="t flex align-items-c">
            <div class="flex-one flex align-items-c">
              <div
                class="l-icon"
                v-if="showChangePay && (item.status == -3 || item.status == 0)"
              >
                <img
                  v-if="changePayIndex.indexOf(index) != -1"
                  src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
                >
                <img
                  v-else
                  src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
                >
              </div>
              <div>订单号 {{ item.order_sn }}</div>
            </div>
            <div class="tr flex align-items-c">
              {{ item.format_create_time }}
              <div>
                <template v-if="item.is_refund">
                  <template v-if="item.refundInfo.status == 0">售后待审核</template>
                  <template v-else-if="item.refundInfo.status == -1">未通过审核</template>
                  <template v-else-if="item.refundInfo.status == -2">取消售后</template>
                  <template v-else-if="item.refundInfo.status == 1">待买家发货</template>
                  <template v-else-if="item.refundInfo.status == 2">待卖家收货</template>
                  <template v-else-if="item.refundInfo.status == 3">售后完成</template>
                </template>
                <template v-else-if="(item.status == -3 || item.status == 0)&&item.is_pay == 0">{{ item.order_type == 3 ? '未付款' : '待付款' }}</template>
                <!-- <template v-else-if="item.status == -4">凭证待审核</template> -->
                <template v-else-if="item.status == 0">待发货</template>
                <template v-else-if="item.status == 1">待收货</template>
                <template v-else-if="item.status == 2">待评价</template>
                <template v-else-if="item.status == 3">已完成</template>
                <template v-else-if="item.status == -1">已退款</template>
                <template v-else-if="item.status == -2">已关闭</template>
              </div>
            </div>
          </div>
          <div class="product">
            <div
              class="item flex align-items-c"
              v-for="(m, i) in item.orderProduct"
              :key="`pro${index}${i}`"
            >
              <div class="img">
                <img
                  :src="m.cart_info.product.image + '!120a'"
                  alt=""
                >
              </div>
              <div class="desc">
                <div class="name fw500 ft12 line1"><span
                    v-if="m.cart_info.product.lot"
                    class="mr5"
                  >LOT{{ m.cart_info.product.lot }} </span>{{ m.cart_info.product.store_name }}</div>
                <div class="price">
                  <span>{{ item.order_type == 3 ? '价格' : '落槌价' }} <b>{{ formatPriceNumber(Number(m.product_price), 2) }}</b></span>
                  <span v-if="Number(m.commission_price)">佣金 <b>{{ formatPriceNumber(Number(m.commission_price), 2) }}</b></span>
                  <span>
                    <template v-if="item.delivery_type == 1">
                      <template v-if="m.cart_info.product.postage_type == 2">运费 {{ formatPriceNumber(Number(m.cart_info.product.postage_price), 2) }}</template>
                      <template v-else-if="m.cart_info.product.postage_type == 3">包邮</template>
                      <template v-else-if="m.cart_info.product.postage_type == 4">到付</template>
                    </template>
                    <template v-else-if="item.delivery_type == 2">自提</template>
                    <template v-else-if="item.delivery_type == 3">委托自提</template>
                  </span>
                </div>
                <div v-if="item.order_type != 3" class="price-b">
                  <span :style="{ color: (item.status == -3 || item.status == 0 ? '#FB7575' : '#000000') }">{{ item.status == -3 || item.status == 0 ? '待支付' : '已支付' }} <b style="font-size: 0.42666rem;">{{ formatPriceNumber(Number(item.pay_price), 2) }}</b></span>
                  <!-- <span
                    class="fw500"
                    style="font-size: 0.26666rem;"
                  >（已优惠300）</span> -->
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-item flex align-items-c">
            <div
              class="btn bor"
              v-if="(item.status == -3 || item.status == 0) && item.is_pay == 0 && item.order_type != 3"
              @click.stop="changePayAction(index)"
              :style="rightBtnStyle"
            >付款</div>
            <!-- <div
              class="btn bor"
              v-if="(item.status == 1)"
            >签署委托发货协议</div> -->
            <template v-if="item.is_refund">
              <div
                class="btn bor"
                @click.stop="goRefundAction(item.refundInfo.refund_order_id)"
                :style="rightBtnStyle"
              >查看售后</div>
            </template>
            <template v-else>
              <div
                class="btn bor"
                v-if="(item.status == 2)"
                @click.stop="orderReceivedAction(index)"
                :style="rightBtnStyle"
              >确认收货</div>
              <div
                class="btn"
                v-if="(item.status == 2 || item.status == 3 || item.status == 4)"
              >查看物流</div>
              <div
                class="btn"
                v-if="(item.status == 3 || item.status == 4)"
              >收货凭证</div>
              <div
                class="btn"
                v-if="(item.status == 1 || item.status == 2 || item.status == 3) && order_refund"
                @click.stop="applyRefundAction(item)"
              >申请售后</div>
            </template>
            <div class="btn">联系客服</div>
          </div>
          <!-- <div class="bottom-txt" v-if="(item.status == -3 || item.status == 0) && m.cart_info.product.commission_config_json.after_commission">{{ parseTime(m.cart_info.product.commission_config_json.commission_time, '{y}年{m}月{d}日') }}起，佣金调整为{{ m.cart_info.product.commission_config_json.after_commission }}%，请尽快完成支付。</div> -->
        </div>
      </div>
      <div
        v-else
        class="list ft12"
      >
        <div
          class="order"
          v-for="(item, index) in list"
          :key="index"
          @click="changeRefundOrderAction(index)"
        >
          <div class="t flex align-items-c">
            <div class="flex-one flex align-items-c">
              <div>售后单号 {{ item.refund_order_sn }}</div>
            </div>
            <div class="tr flex align-items-c">
              {{ item.format_create_time }}
              <div>
                <template v-if="item.status == 0">待审核</template>
                <template v-else-if="item.status == -1">审核失败</template>
                <template v-else-if="item.status == -2">退款关闭</template>
                <template v-else-if="item.status == 1">待退货</template>
                <template v-else-if="item.status == 2">待收货</template>
                <template v-else-if="item.status == 3">已完成</template>
              </div>
            </div>
          </div>
          <div class="product">
            <template
              v-for="(m, i) in item.refundProduct"
              :key="`pro${index}${i}`"
            >
              <template v-if="m.product">
                <div class="item flex align-items-c">
                  <div class="img">
                    <img
                      :src="m.product.cart_info.product.image + '!120a'"
                      alt=""
                    >
                  </div>
                  <div class="desc desc-new">
                    <div class="name fw500 ft12">{{ m.product.cart_info.product.store_name }}</div>
                    <div class="price">
                      <span>落槌价 <b>{{ formatPriceNumber(Number(m.product.product_price), 2) }}</b></span>
                      <span>佣金 <b>{{ formatPriceNumber(Number(m.product.commission_price), 2) }}</b></span>
                      <span>
                        <template v-if="m.product.cart_info.product.postage_type == 2">运费 {{ formatPriceNumber(Number(m.product.cart_info.product.postage_price), 2) }}</template>
                        <template v-else-if="m.product.cart_info.product.postage_type == 3">包邮</template>
                        <template v-else-if="m.product.cart_info.product.postage_type == 4">到付</template>
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
          <div class="bottom-item flex align-items-c">
            <div
              class="btn bor"
              :style="rightBtnStyle"
            >售后详情</div>
            <div class="btn">联系客服</div>
          </div>
          <!-- <div class="bottom-txt" v-if="(item.status == -3 || item.status == 0) && m.cart_info.product.commission_config_json.after_commission">{{ parseTime(m.cart_info.product.commission_config_json.commission_time, '{y}年{m}月{d}日') }}起，佣金调整为{{ m.cart_info.product.commission_config_json.after_commission }}%，请尽快完成支付。</div> -->
        </div>
      </div>
    </van-list>
    <div
      class="order-bottom"
      v-if="showChangePay"
    >
      <!-- <div
        class="l l-icon"
        @click="changeAllAction"
      >
        <img
          v-if="changeAll"
          src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
        >
        <img
          v-else
          src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
        >
        <div>全选</div>
      </div> -->
      <div class="c">已选择<span>{{ changePayIndex.length }}</span>个订单</div>
      <div
        class="r"
        :style="rightBtnStyle"
        @click.stop="goPayAction"
      >{{changePayIndex.length > 1 ? '合并付款' : '去付款'}}</div>
    </div>
    <!-- <div class="no-data flex item-flex-center" style="padding: 0.61333rem 0 1.17333rem 0;">
      <div class="heng"></div>
      <div class="txt">我是有底线的</div>
      <div class="heng"></div>
    </div> -->
  </div>
</template>

<script>
import { ref, onBeforeMount, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { orderList, refundList, orderReceived } from "@/api/order";
import { getConfigClassKeys } from "@/api/system";
import { Toast, Dialog } from "vant";
import { formatPriceNumber, parseTime, isWeiXin } from "@/utils/util";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const tabIndex = ref("");

    const loading = ref(true);
    const finished = ref(false);
    const total = ref(0);
    const list = ref([]);
    const queryParams = ref({
      page: 1,
      limit: 10,
    });

    const order_refund = ref(0); // 开启退款
    onBeforeMount(() => {
      loading.value = false;
      tabIndex.value = route.query.status || "0";
      queryParams.value.status = tabIndex.value;
      if (tabIndex.value == 6) {
        loadRefund();
      } else {
        loadList();
      }

      getConfigClassKeys("auction_rule_set").then((res) => {
        order_refund.value = res.data.order_refund;
      });
    });
    // 风格颜色
    const color_data = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    // 右按钮样式
    const leftBtnStyle = computed(() => {
      return store.getters.left_button_style
        ? store.getters.left_button_style
        : {};
    });

    // 选择标签时间
    const changeTabAction = (tab) => {
      list.value = [];
      tabIndex.value = tab;
      queryParams.value.status = tab;
      queryParams.value.page = 1;
      finished.value = false;
      loading.value = false;
      if (tab == 6) {
        loadRefund();
      } else {
        loadList();
      }
    };

    const loadNextPage = () => {
      loading.value = false;
      if (tabIndex.value == 6) {
        loadRefund();
      } else {
        loadList();
      }
    };

    const loadRefund = () => {
      if (finished.value) return false;
      if (loading.value) return false;
      loading.value = true;
      refundList(queryParams.value)
        .then((res) => {
          total.value = res.data.count;
          if (queryParams.value.page === 1) {
            list.value = res.data.list;
          } else {
            list.value = list.value.concat(res.data.list);
          }
          if (list.value.length >= res.data.count) {
            finished.value = true;
          }
          queryParams.value.page += 1;
          loading.value = false;
        })
        .catch((message) => Toast(message));
    };

    const loadList = () => {
      if (finished.value) return false;
      if (loading.value) return false;
      loading.value = true;
      orderList(queryParams.value)
        .then((res) => {
          total.value = res.data.count;
          if (queryParams.value.page === 1) {
            list.value = res.data.list;
          } else {
            list.value = list.value.concat(res.data.list);
          }
          if (list.value.length >= res.data.count) {
            finished.value = true;
          }
          queryParams.value.page += 1;
          loading.value = false;
        })
        .catch((message) => Toast(message));
    };

    const showChangePay = ref(false); // 开启多选支付
    const changePayIndex = ref([]); // 多选index索引
    const changeAll = ref(false); // 是否全选

    const changePayAction = (index) => {
      if (list.value[index].order_type == 3) {
        return false;
      }
      if(changePayIndex.value.length){
        if(list.value[index].mer_id != list.value[changePayIndex.value[0]].mer_id){
          Toast("请选择同一商家的订单");
          return false;
        }
      }
      if (showChangePay.value) {
        changeOrderAction(index);
      } else {
        changePayIndex.value = [index];
        showChangePay.value = true;
      }
    };

    const changeOrderAction = (index) => {
      if (list.value[index].order_type == 3) {
        return false;
      }
      if (showChangePay.value) {
        const ind = changePayIndex.value.indexOf(index);
        if (ind === -1) {
          changePayIndex.value.push(index);
          let val = [];
          list.value.forEach((m, i) => {
            if (m.status == -3 || m.status == 0) {
              val.push(i);
            }
          });
          if (changePayIndex.value.length === val.length) {
            changeAll.value = true;
          }
        } else {
          changePayIndex.value.splice(ind, 1);
        }
        if (changePayIndex.value.length === 0) {
          showChangePay.value = false;
        }
      } else {
        if (list.value[index].status == -3 || list.value[index].status == 0) {
          const isWx = isWeiXin();
          if (isWx) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.getEnv((res) => {
              if (res.miniprogram) {
                // eslint-disable-next-line no-undef
                wx.miniProgram.redirectTo({
                  url:
                    "/pages/orderConfirm/index?order_id=" +
                    list.value[index].order_id,
                });
              } else {
                router.push({
                  path: "/pages/orderConfirm/index",
                  query: { order_id: list.value[index].order_id },
                });
              }
            });
          } else {
            router.push({
              path: "/pages/orderConfirm/index",
              query: { order_id: list.value[index].order_id },
            });
          }
        } else {
          router.push({
            path: "/order/detail",
            query: { id: list.value[index].order_id },
          });
        }
      }
    };

    const changeRefundOrderAction = (index) => {
      router.push({
        path: "/order/refund_detail",
        query: { id: list.value[index].refund_order_id },
      });
    };

    const changeAllAction = () => {
      if (showChangePay.value) {
        if (changeAll.value) {
          changeAll.value = false;
          changePayIndex.value = [];
          showChangePay.value = false;
        } else {
          let val = [];
          list.value.forEach((m, i) => {
            if (m.status == -3 || m.status == 0) {
              val.push(i);
            }
          });
          changePayIndex.value = val;
          changeAll.value = true;
        }
      }
    };

    const goPayAction = () => {
      const val = [];
      changePayIndex.value.forEach((m) => {
        const item = list.value[m];
        if (item.status === -3 || item.status === 0) {
          val.push(item.order_id);
        }
      });
      console.log(val);
      if (val.length > 0) {
        changeAll.value = false;
        changePayIndex.value = [];
        showChangePay.value = false;
        const isWx = isWeiXin();
        if (isWx) {
          // eslint-disable-next-line no-undef
          wx.miniProgram.getEnv((res) => {
            if (res.miniprogram) {
              // eslint-disable-next-line no-undef
              wx.miniProgram.redirectTo({
                url: "/pages/orderConfirm/index?order_id=" + val.join(","),
              });
            } else {
              router.push({
                path: "/pages/orderConfirm/index",
                query: { order_id: val.join(",") },
              });
            }
          });
        } else {
          router.push({
            path: "/pages/orderConfirm/index",
            query: { order_id: val.join(",") },
          });
        }
      }
    };

    const applyRefundAction = (item) => {
      // 申请售后
      localStorage.setItem("refundProduct", JSON.stringify(item));
      router.push("/order/apply_refund");
    };
    let lock = false;
    const orderReceivedAction = (index) => {
      // 确认收货
      Dialog.confirm({
        title: "确定收到货物？",
        message:
          "为保障您的售后权益，请收到货确认无误后，在确认收货哦！确认收货后，货款将打入商家账户！",
      }).then(() => {
        if (lock) return false;
        lock = true;
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });

        orderReceived(list.value[index].order_id)
          .then(() => {
            Toast.clear();
            Toast("操作成功");
            lock = false;
            list.value[index].status = 3;
          })
          .catch((message) => {
            Toast.clear();
            Toast(message);
            lock = false;
          });
      });
    };

    const goRefundAction = (id) => {
      router.push("/order/refund_detail?id=" + id);
    };

    // orderReceived

    return {
      tabIndex,
      loading,
      finished,
      total,
      list,
      rightBtnStyle,
      leftBtnStyle,
      showChangePay,
      changePayIndex,
      changeAll,
      order_refund,
      color_data,

      changeTabAction,
      loadList,
      formatPriceNumber,
      parseTime,
      changePayAction,
      changeOrderAction,
      goPayAction,
      changeAllAction,
      applyRefundAction,
      orderReceivedAction,
      changeRefundOrderAction,
      goRefundAction,
      loadNextPage,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 100vh;
  background: #f5f5f5;
  box-sizing: border-box;
  padding-top: 96px;

  .tab {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 96px;
    background: #fff;

    .item {
      margin-left: 48px;
      height: 96px;
      line-height: 96px;
      position: relative;
      font-size: 27px;

      &:first-child {
        margin-left: 32px;
      }

      .bg {
        width: 36px;
        height: 6px;
        position: absolute;
        left: 50%;
        bottom: 18px;
        margin-left: -18px;
        border-radius: 4px;
        background: #dd0000;
        display: none;
      }

      &.curr {
        color: #dd0000;
        font-weight: 500;

        .bg {
          display: block;
        }
      }
    }
  }

  .list {
    .order {
      margin-top: 20px;
      background: #fff;
      padding: 0 32px 32px 32px;

      .t {
        height: 80px;
        .flex-one {
          color: #666;
        }

        .tr {
          color: #999;

          div {
            color: #fb7575;
            margin-left: 16px;
          }
        }
      }
    }
  }

  .bottom-item {
    padding-top: 20px;

    .btn {
      min-width: 160px;
      height: 60px;
      line-height: 56px;
      text-align: center;
      box-sizing: border-box;
      padding: 0 24px;
      background: #ffffff;
      border-radius: 8px;
      border: 2px solid #dddddd;
      margin-right: 16px;
      color: #333;
      font-size: 24px !important;

      &.bor {
        line-height: 52px;
      }
    }
  }

  .bottom-txt {
    color: #c1955c;
    margin-top: 20px;
    max-height: 9999px;
  }

  .product {
    .item {
      border-top: 2px solid #eeeeee;
      padding-top: 20px;
      margin-top: 20px;
      &:first-child {
        border-top: none;
        padding-top: 0;
        margin-top: 0;
      }
      .img {
        width: 128px;
        height: 128px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 8px;
        }
      }

      .desc {
        flex: 1;
        margin-left: 18px;
        overflow: hidden;
        color: #000;

        .price {
          margin-top: 20px;
          color: #999999;
          span {
            margin-right: 10px;
          }
        }

        .price-b {
          margin-top: 8px;

          b {
            margin-left: 6px;
          }
        }
      }
      .desc-new {
        .name {
          line-height: 35px;
          height: 70px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
}
.hasBottom {
  padding-bottom: 164px;
}

.order-bottom {
  height: 96px;
  padding-bottom: 68px;
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;

  .l,
  .c {
    padding-left: 32px;
    height: 96px;
    display: flex;
    align-items: center;
    color: #666;
    span {
      color: #fb7575;
      font-weight: 500;
    }
  }

  .c {
    flex: 1;
  }

  .r {
    width: 200px;
    height: 96px;
    line-height: 96px;
    text-align: center;
    border-radius: 0 !important;
  }
}

.l-icon {
  img {
    width: 40px;
    height: 40px;
    margin-right: 4px;
    display: block;
  }
}
</style>